import Head from "next/head";
import { useEffect } from "react";
import "../css/style.css";
import "../css/skin/skin-1.css";
import "../styles/globals.css";
import Link from "next/link";
import TagManager from 'react-gtm-module';
import NextNProgress from 'nextjs-progressbar';
import { Cairo } from 'next/font/google'

const cairo = Cairo({ subsets: ['arabic'], variable: '--cairo-font' })

function MyApp({ Component, pageProps }) {
	useEffect( () => { document.querySelector("body").classList.add("rtl") } );


  useEffect(() => {
		TagManager.initialize({ gtmId: 'GTM-WD9PQXN' });
  }, []);

  return (
    <>
		<NextNProgress color="#F14525" height={5} />
		<Head>
			<title>الذكاء الإلكتروني - eSmart</title>
			<link rel="icon" type="image/png" sizes="16x16" href="/images/favicon.png"/>
			<meta name="robots" content="index, follow" />
			<meta name="google-site-verification" content="0gtj_hwwOPpFzIen4LSXA1z0iccaZfG365pWgumOorg" />
        </Head>	
		<style jsx global>{`
		:root{
			--cairo-font: ${cairo.style.fontFamily};
		}
      `}</style>
		<div className="page-wraper">
			<Component {...pageProps} />
		</div>
		
		<a href="https://api.whatsapp.com/send?phone=966504449747" title="whatsapp" target="_blank">
			<button
			  className="whatsapp icon-up"
			  type="button"
			  style={{ display: "inline-block" }}
			>
			  <i className="fa fa-whatsapp" />
			  تواصل عبر الواتساب
			</button>
		</a>
		<Link legacyBehavior href="/register">
			<a className="register btn btn-primary rounded-md" title="سجل الآن" style={{ display: "inline-block" }}>
				
				سجل الآن
				<i className="fa fa-angle-left m-r10"></i>
				
			</a>
		</Link>
    </>
  );
}

export default MyApp;
